/* @import './views/styles/mxStyle.css'; */
@import './views/styles/common.css';
@import './views/styles/haki.css';
/* @import './views/styles/newStyle.css'; */

body {
  margin: 0;
  background-color: rgb(247, 247, 247);
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


.Toastify__toast-icon {
  display: inline !important;
}