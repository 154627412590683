.dark-text {
    color: rgb(8, 0, 50) !important;
}

.f16 {
    font-size: 141px !important;
}

.f16 {
    font-size: 16px !important;
}

.f18 {
    font-size: 18px !important;
}

.f20 {
    font-size: 20px !important;
}

.f22 {
    font-size: 22px !important;
}

.f24 {
    font-size: 24px !important;
}

.px-bold {
    font-weight: bold;
}

.px-medium {
    font-weight: 500;
}

.px-lighter {
    font-weight: lighter;
}

.px-light {
    font-weight: normal;
}

.nav-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px 0px 50px !important;
    margin-top: 24px;
    position: absolute;
    z-index: 1;
    width: 100vw;
    background-color: transparent;
    -webkit-transform: translate3d(0, 0, 0);
}

.navbar--logo {
    width: auto;
    height: auto;
    cursor: pointer;
    /* animation: rotate 0.7s ease-in-out 0.5s; */
}

.navbar--btn {
    display: flex;
    list-style: none;
    align-items: center;

}

.navbar--btn-item {
    margin: 0 75px 0 0px;
    padding: 0 4px;
    color: #C2DC55;
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
}

.navbar--btn-item:hover {

    color: #C2DC55;
    cursor: pointer;

}

a {
    text-decoration: none;
}

/* nav {
    font-family: monospace;
  } */

ul {
    background: transparent;
    list-style: none;
    margin: 0;
    padding-left: 0;
}

li {
    color: black;
    background: transparent;
    display: block;
    float: left;
    padding: .5rem .5rem .5rem 0;
    position: relative;
    text-decoration: none;
    transition-duration: 0.5s;
}

li a {
    color: black;
}

li:hover,
li:focus-within {
    background: transparent;
    cursor: pointer;
}

li:focus-within a {
    outline: none;
}

ul li ul {
    background: transparent;
    visibility: hidden;
    opacity: 0;
    min-width: 5rem;
    position: absolute;
    transition: all 0.5s ease;
    margin-top: .5rem;
    left: 0;
    display: none;
    cursor: pointer;
}

ul li:hover>ul,
ul li:focus-within>ul,
ul li ul:hover,
ul li ul:focus {
    visibility: visible;
    opacity: 1;
    display: block
}

ul li ul li {
    clear: both;
    width: 100%;
}

page {
    background: grey;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
  }
  page[size="A4"] {  
    width: 21cm;
    height: 29.7cm; 
  }

table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 4px;
}

.pk-btn-header {
    border: 1px solid rgb(255, 32, 127) !important;
    border-radius: 2rem;
    font-size: 12px;
    font-weight: 500;
    background-color: rgb(255, 32, 127);
    color: #fff;
    padding: 12px 28px 12px 28px !important;
}

.pk-btn {
    border: 1px solid #C2DC55;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.5px;
    letter-spacing: 0.21px;
    background-color: #C2DC55;
    color: #285E4D;
    padding: 16px 32px 16px 32px;
    margin-top: 42px;
}

.px-topSec {
    /* border: 1px solid red; */
    padding-top: 150px;
    margin-left: 75px;
    margin-right: 75px;
}

.px-top {
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: row;
    height: 100vh !important;
}

.px-top-sub-1 {
    flex: 1;
}

.px-top-sub-2 {
    flex: 2;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.px-footer-data {
    background-color: transparent;
    /* width: 100%; */
    /* justify-content: space-between; */
    padding: 10px 0px 10px 0px !important;
    margin: 0 50px 0 50px !important;
}